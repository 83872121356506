<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <!-- <div class="group_1 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/img/psotzt4w9l6rmvx3ahf076x6qxzqtw1lna11c6b24-95fe-4e2f-ae3d-c2983b6c39e8.png"
        />
        <span class="text_1">了解运动官</span>
        <span class="text_2">产品服务</span>
        <span class="text_3">运动瞬间</span>
        <div class="text-wrapper_1 flex-col"><span class="text_4">数字体育</span></div>
        <span class="text_5">加入我们</span>
      </div> -->
      <div class="group_2 flex-col">
        <div class="box_2 flex-row justify-around">
          <el-tooltip
            placement="top"
            effect="light"
            transition="el-zoom-in-center"
          >
            <div slot="content" style="width: 0.8rem; height: 0.8rem">
              <el-image
                :src="require('../assets/img/businessQrcode.png')"
              ></el-image>
            </div>
            <div class="box_3 flex-row justify-around align-center">
              <div class="image-text_1 flex-row justify-around align-center">
                <img
                  class="label_1"
                  referrerpolicy="no-referrer"
                  src="../assets/img/psr6ifblk0dgn0s86lkz0qlrdq2ziu71x5b2600f9-7f3e-4076-9aeb-0eed331e466e.png"
                />
                <div class="text-group_1">
                  <!-- <span class="text_6"></span> -->
                  <span class="text_7">android版本下载</span>
                </div>
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            placement="top"
            effect="light"
            transition="el-zoom-in-center"
          >
            <div slot="content" style="width: 0.8rem; height: 0.8rem">
              <el-image
                :src="require('../assets/img/businessQrcode.png')"
              ></el-image>
            </div>
            <div class="box_4 flex-row justify-around align-center">
              <div class="image-text_2 flex-row justify-around align-center">
                <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="../assets/img/ps8ri0kf372dtxy69nas47cnkdpn5v913nndd2eead2-5960-4b78-99b3-d012c9c51a06.png"
                />
                <div class="text-group_2">
                  <span class="text_10">ios版本下载</span>
                </div>
              </div>
            </div>
          </el-tooltip>
        </div>
        <div class="box_5 flex-col">
          <span class="text_11">数字体育协同服务SAAS平台</span>
          <span class="text_12"
            >DIGITAL&nbsp;SPORTS&nbsp;COLLABORATIVE&nbsp;SERVICE&nbsp;SAAS&nbsp;PLATFORM</span
          >
          <div class="text-wrapper_2 flex-col">
            <span class="text_13"
              >供应链&nbsp;+&nbsp;智慧体育SaaS云平台&nbsp;+&nbsp;运动榜样&nbsp;+&nbsp;平台用户</span
            >
          </div>
        </div>
      </div>
      <div class="group_3 flex-row">
        <span class="text_14">S P O R T</span>
        <span class="text_15"
          >Digital&nbsp;Sports&nbsp;Business&nbsp;Model</span
        >
        <span class="text_16">夯实数字体育商业模式</span>
        <div class="group_4 flex-col"></div>
        <div class="group_5 flex-col"></div>
        <div class="group_6 flex-col"></div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="../assets/img/psse04f85t4sqblb6eix8ye7s7i699a9q3db7f173ce-d608-4ba1-a68a-dfc3f834b8de.png"
        />
        <span class="text_17">O F F I C E R S</span>
        <div class="text-wrapper_3">
          <span class="paragraph_1">
            具备自生长力的分布式营销模式：B2S2b2
            <br />
          </span>
          <span class="paragraph_2">
            （标准化体育产品服务供应链+智慧体育SaaS云平台+运动榜样社群+智能运动设备硬件）
            <br />
            <!-- <br /> -->
          </span>
          <span class="paragraph_3">
            传统B2B或者B2C模式对于三者是割裂状态的，
            <br />
            B2S2b2C模式
            <br />
            最大优势在于B+S和S+b共同服务C，它是一个全新的协同网络，
            <br />
            这个模式将会带来比传统模式更大的价值创新，
            <br />
            并形成四方共赢的良性体育产业生态闭环。
            <br />
          </span>
        </div>
      </div>
      <div class="group_7 flex-col">
        <span class="text_18">赋能式体育产业数字化转型</span>
        <span class="text_19">Digital&nbsp;Transformation</span>
        <div class="box_6 flex-row">
          <div class="block_1 flex-col"></div>
          <div class="block_2 flex-col"></div>
          <div class="block_3 flex-col"></div>
        </div>
        <div class="text-wrapper_4">
          <span class="paragraph_4">
            利用互联网、大数据、云计算为城市体育产业赋能，
            <br />
          </span>
          <!-- <span class="text_20"></span> -->
          <span class="paragraph_5">
            通过可持续的运营模式，构建体育生活服务聚合平台，
            <br />
            让体育服务商具备互联网化的运营能力，
            <br />
            从线下到网上、到掌上，数字化转型、集群化发展。
          </span>
        </div>
      </div>
      <div class="group_8 flex-row justify-between">
        <div class="block_4 flex-col">
          <div class="section_1 flex-row">
            <div class="text-wrapper_5 flex-col">
              <span class="text_21">树立运动榜样带动全民体育</span>
              <span class="text_22"
                >Leading&nbsp;the&nbsp;movement&nbsp;for&nbsp;all</span
              >
            </div>
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="../assets/img/psh3u1o4gv81k9usseo2p8zfhhsjfsxc3t97bded085-7b17-4884-97d6-ca2304d603dc.png"
            />
            <img
              class="label_4"
              referrerpolicy="no-referrer"
              src="../assets/img/psgdgdi1x4vts1qz3oyptq8tfcud9ua638ed3a4e0-f282-4d19-a86d-bda63b7045f8.png"
            />
          </div>
          <div class="section_2 flex-row">
            <div class="box_7 flex-col"></div>
            <div class="box_8 flex-col"></div>
            <div class="box_9 flex-col"></div>
          </div>
          <span class="paragraph_6">
            运动官智慧体育服务中心通过建立创客工作室孵化“运动榜样”，
            <br />
            每一位运动榜样在平台上进行证书认证后
            <br />
            即可为用户提供体育锻炼的组织和指导服务。
            <br />
            由运动榜样引领科学健身，在运动场馆、体育俱乐部、社区、学校、
            <br />
            商圈等组织体育活动，
            <br />
            推广普及科学健身知识和方法，
            <br />
            通过广泛组织体育活动激发全民健身热情，促进体育消费；
            <br />
            线上评价制度有利于运动榜样更好地为广大用户提供健身指导服务。
          </span>
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="../assets/img/ps4nwmob2jckg07oe6vufar8xpex73011ksjcbe6cf7e-73ef-4d4b-9884-36438d3612ac.png"
          />
        </div>
        <div class="block_5 flex-col">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="../assets/img/pscuyz4rnutunvx87rhue70jysnqwr8m56aff39a4b6-b41f-4f1d-8150-bb26d438d3f2.png"
          />
          <div class="box_10 flex-row">
            <img
              class="label_5"
              referrerpolicy="no-referrer"
              src="../assets/img/pskjroe7uawx8pw8131x9qvsz8cec4pck7f204e75-0f76-4af2-94d5-7e66a671830f.png"
            />
            <img
              class="label_6"
              referrerpolicy="no-referrer"
              src="../assets/img/ps2g916atsyaw8pwqtrfldn66lvei7otd9fd95cf40-f081-47b4-a0ed-75590693859c.png"
            />
            <div class="text-wrapper_6 flex-col ">
              <span class="text_23">预付金安全</span>
              <span class="text_24">Secure&nbsp;payment&nbsp;methods</span>
            </div>
          </div>
          <span class="paragraph_7">
            运动官与央行颁发支付牌照的第三方支付机构合作，
            <br />
            会员账目的资金由银行存管做背书，
            <br />
            信息流和资金流实时同步第三方支付系统及存管
            <br />
            银行，保障体育消费者预付金安全，化解社会风险。
          </span>
        </div>
      </div>
      <div class="group_9 flex-col">
        <div class="box_11 flex-col">
          <span class="text_25">体育资源及服务协同平台</span>
          <span class="text_26">Sports&nbsp;Service&nbsp;Platform</span>
          <div class="section_3 flex-row">
            <div class="section_4 flex-col"></div>
            <div class="section_5 flex-col"></div>
            <div class="section_6 flex-col"></div>
          </div>
          <div class="text-wrapper_7">
            <span class="paragraph_8">
              运动官体育云SaaS供应链管理系统，定位垂直体育行业，
              <br />
              利用数字技术实现体育产业上下游各要素、环节的数字化、网络化，
              <br />
              助力体育商家实现战略业务转型。
              <br />
              提供体育产业撮合、交易、履约、结算
            </span>
            <span class="paragraph_9">
              ,
              <br />
            </span>
            <span class="paragraph_10">
              优化和协同产业链上下游企业，建设体育产业链数字化生态圈，
              <br />
              形成数字化体育产业集群，积极打造产融一体化产业链，
              <br />
              建立体育产业供应链协同平台。
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="group_10 flex-col ">
        <div class="image-wrapper_1 flex-row justify-around">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="../assets/img/psgcwtcbipwju1p58z24sq786ta7slx2ooa8dedac54-9386-45f4-ae7b-d0961b4261c4.png"
          />
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="../assets/img/ps6jbejm8g66xrn15se94qbk5goiuqtrco46ae3d75-60aa-41f3-937a-18555fbc8d32.png"
          />
        </div>
        <div class="text-wrapper_8 flex-row justify-around">
          <span class="text_27">运动生活&nbsp;快乐有我&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;科学运动引领者</span>
          <span class="text_28">垂直体育分销系统&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;科学运动引领者</span>
        </div>
        <div class="box_12 flex-row justify-around">
          <div class="box_13 flex-row justify-around ">
            <div class="image-text_3 flex-row justify-around align-center">
              <img
                class="label_7"
                referrerpolicy="no-referrer"
                src="../assets/img/psdp53xwy5m2r1qoja6a9eykcs1z4a5xd16155fdc0-4d50-4034-b8ec-a34337d257a6.png"
              />
              <div class="text-group_3">
                <span class="text_30">android版本下载</span>
              </div>
            </div>
          </div>
          <div class="box_14 flex-row justify-around ">
            <div class="image-text_4 flex-row justify-around align-center">
              <img
                class="label_8"
                referrerpolicy="no-referrer"
                src="../assets/img/ps8qsrfkbavaoae9spwgz9cakgex0ndc2ha8e5b6db6-5e29-4776-9c8c-a8cc467e374b.png"
              />
              <div class="text-group_4">
                <span class="text_33">ios版本下载</span>
              </div>
            </div>
          </div>
          <div class="box_15 flex-row justify-around">
            <div class="image-text_5 flex-row justify-around align-center ">
              <img
                class="label_9"
                referrerpolicy="no-referrer"
                src="../assets/img/ps0h5cu70d4sqimfsxstbia2k766jj91mdm29f7e763-3e2e-496c-a271-96e7c03d0d76.png"
              />
              <div class="text-group_5">
                <span class="text_35">android版本下载</span>
              </div>
            </div>
          </div>
          <div class="box_16 flex-row justify-around">
            <div class="image-text_6 flex-row justify-around align-center">
              <img
                class="label_10"
                referrerpolicy="no-referrer"
                src="../assets/img/psfyk7xg5h30se5hj9355xpvi8r3b5hvcxdea7284a-b240-4d85-9853-f474559a906e.png"
              />
              <div class="text-group_6">
                <span class="text_38">ios版本下载</span>
              </div>
            </div>
          </div>
        </div>
        <span class="text_39">
          2019-2022&nbsp;&nbsp;&nbsp;运动官&nbsp;&nbsp;-&nbsp;&nbsp;科学运动引领者&nbsp;&nbsp;鲁ICP备19063359号-1&nbsp;&nbsp;广播电视节目制作经营&nbsp;许可证编号：（鲁）字&nbsp;&nbsp;第2316号&nbsp;&nbsp;联系方式：400-857-1778&nbsp;&nbsp;&nbsp;举报邮箱：jvbao&#64;compay.com
        </span>
      </div> -->
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="../assets/img/ps82e77ejb0kbnhvbf2vs46jpb2snuc6qn05862316-6e8b-40b4-8355-1133d9bfc4d8.png"
      />
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="../assets/img/pski4nje2l0ysmoze9dxwpdf9c6lp593vyl1f575590-b36e-4da8-8a44-73c89b9569e9.png"
      />
    </div>
    <div class="download">
      <div class="download-content">
        <div class="download-content-left">
          <div class="download-content-left-info">
            <div class="iconlogo">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_white.png')"
              ></el-image>
              <span class="iconlogotext">运动官</span>
            </div>
            <div class="download-content-left-introduce">
              <span>运动生活&nbsp;&nbsp;&nbsp;快乐有我<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
            <div class="iconlogo" style="margin-top: 0.8rem">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_black.png')"
              ></el-image>
              <span class="iconlogotext">运动官商家版</span>
            </div>
            <div class="download-content-left-introduce">
              <span>垂直体育分销系统<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="download-content-right">
          <div class="download-content-right-mode"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
  created() {
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style  lang="less" scoped>
@import url("../inconfont/common.css");
@import url("../inconfont/digitalSports.css");
 .download {
    width: 100%;  
    // height: 4.5rem;
    background-color: rgba(34, 34, 34, 1);
    margin-top: 0.2rem;
    .download-content {
      display: flex;
      // height: 100%;
      .download-content-left {
        width: 3rem;
        // height: 100%;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        background-color: rgba(34, 34, 34, 1);
        .download-content-left-info {
          width: 2rem;
          // height: 3.5rem;
          margin: 0 auto;
          background-color: rgba(34, 34, 34, 1);
          color: #fff;
          .iconlogo {
            display: flex;
            align-items: center;
            .iconlogoimg {
              width: 0.15rem;
              height: 0.15rem;
            }
            .iconlogotext {
              font-size: 0.11rem;
              color: #fff;
              margin-left: 0.04rem;
            }
          }
          .download-content-left-introduce {
            margin-top: 0.15rem;
            font-size: 0.07rem;
            line-height: 28px;
          }
          .download-content-left-mode {
            display: flex;
            justify-content: space-between;
            margin-top: 0.4rem;
            .download-content-left-mode-apple {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u912.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
            .download-content-left-mode-android {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u913.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
          }
        }
      }
      .download-content-right {
        position: relative;
        flex: 1;
        background: url("../assets/img/u90.jpg") no-repeat;
        background-size: 100% 100%;
        .download-content-right-mode {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #111;
          opacity: 0.5;
        }
      }
    }
  }

</style>


